
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93Y1gmTns0GxMeta } from "/home/runner/work/es-cms-articles/es-cms-articles/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93YM1lRLoNU3Meta } from "/home/runner/work/es-cms-articles/es-cms-articles/node_modules/@energysage/storyblok-shared/pages/global/components/cta-banners/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93JNebLYMvARMeta } from "/home/runner/work/es-cms-articles/es-cms-articles/pages/global/components/cta-fixed-upper-banners/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93HtSXakFn2TMeta } from "/home/runner/work/es-cms-articles/es-cms-articles/node_modules/@energysage/storyblok-shared/pages/global/components/eeat-infos/[...slug].vue?macro=true";
import { default as _91slug_936CSluVOSMxMeta } from "/home/runner/work/es-cms-articles/es-cms-articles/node_modules/@energysage/storyblok-shared/pages/global/components/sticky-zip-cta/[slug].vue?macro=true";
import { default as _91slug_93L9IwtWI75zMeta } from "/home/runner/work/es-cms-articles/es-cms-articles/node_modules/@energysage/storyblok-shared/pages/global/components/vertical-cta-cards/[slug].vue?macro=true";
import { default as _91_46_46_46slug_93iXZ4NxFOEqMeta } from "/home/runner/work/es-cms-articles/es-cms-articles/node_modules/@energysage/storyblok-shared/pages/global/navigation/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_937dMajG6I8qMeta } from "/home/runner/work/es-cms-articles/es-cms-articles/pages/rss-preview/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93cHxhZJUEIRMeta } from "/home/runner/work/es-cms-articles/es-cms-articles/pages/rss/[...slug].vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/home/runner/work/es-cms-articles/es-cms-articles/pages/[...slug].vue")
  },
  {
    name: "global-components-cta-banners-slug",
    path: "/global/components/cta-banners/:slug(.*)*",
    meta: _91_46_46_46slug_93YM1lRLoNU3Meta || {},
    component: () => import("/home/runner/work/es-cms-articles/es-cms-articles/node_modules/@energysage/storyblok-shared/pages/global/components/cta-banners/[...slug].vue")
  },
  {
    name: "global-components-cta-fixed-upper-banners-slug",
    path: "/global/components/cta-fixed-upper-banners/:slug(.*)*",
    meta: _91_46_46_46slug_93JNebLYMvARMeta || {},
    component: () => import("/home/runner/work/es-cms-articles/es-cms-articles/pages/global/components/cta-fixed-upper-banners/[...slug].vue")
  },
  {
    name: "global-components-eeat-infos-slug",
    path: "/global/components/eeat-infos/:slug(.*)*",
    meta: _91_46_46_46slug_93HtSXakFn2TMeta || {},
    component: () => import("/home/runner/work/es-cms-articles/es-cms-articles/node_modules/@energysage/storyblok-shared/pages/global/components/eeat-infos/[...slug].vue")
  },
  {
    name: "global-components-sticky-zip-cta-slug",
    path: "/global/components/sticky-zip-cta/:slug()",
    meta: _91slug_936CSluVOSMxMeta || {},
    component: () => import("/home/runner/work/es-cms-articles/es-cms-articles/node_modules/@energysage/storyblok-shared/pages/global/components/sticky-zip-cta/[slug].vue")
  },
  {
    name: "global-components-vertical-cta-cards-slug",
    path: "/global/components/vertical-cta-cards/:slug()",
    meta: _91slug_93L9IwtWI75zMeta || {},
    component: () => import("/home/runner/work/es-cms-articles/es-cms-articles/node_modules/@energysage/storyblok-shared/pages/global/components/vertical-cta-cards/[slug].vue")
  },
  {
    name: "global-navigation-slug",
    path: "/global/navigation/:slug(.*)*",
    meta: _91_46_46_46slug_93iXZ4NxFOEqMeta || {},
    component: () => import("/home/runner/work/es-cms-articles/es-cms-articles/node_modules/@energysage/storyblok-shared/pages/global/navigation/[...slug].vue")
  },
  {
    name: "rss-preview-slug",
    path: "/rss-preview/:slug(.*)*",
    meta: _91_46_46_46slug_937dMajG6I8qMeta || {},
    component: () => import("/home/runner/work/es-cms-articles/es-cms-articles/pages/rss-preview/[...slug].vue")
  },
  {
    name: "rss-slug",
    path: "/rss/:slug(.*)*",
    meta: _91_46_46_46slug_93cHxhZJUEIRMeta || {},
    component: () => import("/home/runner/work/es-cms-articles/es-cms-articles/pages/rss/[...slug].vue")
  }
]